<template>
    <header class="wrapper">
        <nav>
            <ul>
                <li
                    class="cursor-pointer"
                    v-for="segment in currentPathSegments"
                    @click="$router.push({ name: segment.name })"
                    :key="segment">
                    <IconNavChevron
                        v-if="segment !== currentPathSegments[0]"
                        class="nav-chevron-icon"
                        :outlineColor="
                            segment !==
                            currentPathSegments[currentPathSegments.length - 1]
                                ? 'rgba(0 9 79 / 0.4)'
                                : 'rgba(0 9 79 / 1)'
                        "
                        :chevronColor="
                            segment !==
                            currentPathSegments[currentPathSegments.length - 1]
                                ? 'rgba(0 9 79 / 0.4)'
                                : 'rgba(0 9 79 / 1)'
                        " />
                    <span>{{ getPageTitle(segment) }}</span>
                </li>
            </ul>
        </nav>
        <button
            class="menu-icon"
            @click="openSidebar">
            <IconHamburgerMenu />
        </button>
        <div class="separator"></div>
        <div class="cal-notif">
            <div class="calendar">
                <IconCalendar class="calendar-icon" />
                <span>{{ getCurrentDate() }}</span>
            </div>
            <!-- <div class="notification">
                <IconNotification class="notification-icon" />
            </div> -->
        </div>
        <div class="separator"></div>
        <div
            class="user cursor-pointer items-center"
            @click="$router.push({ name: 'profile' })">
            <div class="avatar">
                <img
                    v-if="profile"
                    :src="profile" />
            </div>
            <div class="data">
                <div class="name">
                    {{
                        user.first_name
                            ? capitalizeFirstLetter(user.first_name) +
                              ' ' +
                              capitalizeFirstLetter(user.last_name)
                            : '-'
                    }}
                </div>
                <div class="role">Admin</div>
            </div>
        </div>
    </header>
</template>

<script setup>
import IconNavChevron from '../icons/IconNavChecron';
import IconCalendar from '../icons/IconCalendar';
// import IconNotification from '../icons/IconNotification';
import IconHamburgerMenu from '../icons/IconHamburgerMenu';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useStore } from '@/store/loading.js';

const store = useStore();
const emits = defineEmits(['open-sidebar']);
const route = useRoute();
const router = useRouter();

const path = ['Dashboard', 'Suite', 'View'];

const openSidebar = () => {
    emits('open-sidebar');
};
const user = ref({});
// const profile = ref('')

onMounted(async () => {
    try {
        const u = localStorage.getItem('user');
        store.user = JSON.parse(u);
        // if (u) {
        //     user.value = store.user;
        //     const img = await loadImage(user.value.profile ,
        //     'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png'
        //     );
        //     profile.value = img
        // }
    } catch (error) {
        console.log(error);
    }
});

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

async function loadImage(imageUrl, defaultUrl) {
    console.log('loadImage');
    return await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(imageUrl);
        img.onerror = () => resolve(defaultUrl);
        img.src = imageUrl;
    });
}

const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    return `${day} ${month}`;
};

const profile = computed(() => {
    user.value = store.user;
    // const img = await loadImage(user.value.profile,
    //     'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png'
    // );
    console.log('user.value.profile', user.value.profile);
    return user.value.profile;
});

const currentPathSegments = computed(() => {
    let paths = route.path.split('/').filter(p => p);
    let result = [];
    for (let i = 0; i < paths.length; i++) {
        result.push({
            name: paths[i],
            path: '/' + paths.slice(0, i + 1).join('/'),
        });
    }
    return result;
});

const getPageTitle = pathSegment => {
    const route = router.getRoutes().find(r => r.path == pathSegment.path);
    return route?.meta.title || 'Unknown';
};
</script>

<style scoped lang="scss">
header.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: rgb(var(--gray-100));
    padding: 20px 16px;

    @media (width > 1024px) {
        padding: 28px 24px 24px;
    }

    @media (width > 1200px) {
        padding: 56px 64px 24px;
    }

    nav {
        display: none;
        margin-right: auto;

        @media (width > 1024px) {
            display: block;
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: 18px;

            li {
                display: flex;
                align-items: center;
                gap: 18px;

                @media (width > 1024px) {
                    gap: 14px;
                }

                @media (width > 1280px) {
                    gap: 18px;
                }

                span {
                    margin-left: 8px;
                    font-size: 14px;
                    line-height: 20px;
                }

                .nav-chevron-icon {
                    width: 20px;
                }
            }
        }
    }

    .menu-icon {
        display: block;

        @media (width > 1024px) {
            display: none;
        }
    }

    .separator {
        width: 1px;
        height: 24px;
        background-color: rgb(var(--gray-200));
    }

    .cal-notif {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (width > 1024px) {
            gap: 0;
        }

        .calendar {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 0 18px;
            border-radius: 1000px;
            gap: 10px;
            background-color: rgb(var(--white));

            @media (width > 1024px) {
                margin: 0 24px;
            }

            .calendar-icon {
                width: 24px;
            }

            span {
                font-size: 14px;
                color: rgb(var(--black));
            }
        }

        .notification {
            position: relative;

            @media (width > 1024px) {
                margin-right: 24px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 4px;
                right: 4px;
                width: 6px;
                height: 6px;
                border-radius: 100px;
                background-color: rgb(var(--blue));
                z-index: 10;
                border: 1px solid rgb(var(--gray-100));
            }

            .notification-icon {
                width: 24px;
            }
        }
    }

    .user {
        display: flex;
        flex-shrink: 0;

        @media (width > 1024px) {
            margin-left: 24px;
        }

        .avatar {
            width: 32px;
            height: 32px;
            margin-right: 16px;
            border-radius: 100px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 3px;
                right: 0px;
                width: 6px;
                height: 6px;
                border-radius: 100px;
                background-color: rgb(var(--green));
                z-index: 10;
                border: 1px solid rgb(var(--gray-100));
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
            }
        }

        .data {
            display: none;
            font-size: 14px;

            @media (width > 1024px) {
                display: block;
            }

            .name {
                color: rgb(var(--black));
            }

            .role {
                color: rgb(var(--gray-400));
            }
        }
    }
}
</style>
