/* eslint-disable no-undef */
import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import mitt from 'mitt';
import VueCookies from 'vue3-cookies';
import VSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'floating-vue/dist/style.css';
import VueAwesomePaginate from 'vue-awesome-paginate';
import 'vue-awesome-paginate/dist/style.css';
import IconSelectArrowVue from './components/icons/IconSelectArrow.vue';
import {
    // Directives
    vTooltip,
    vClosePopper,
    // Components
    Dropdown,
    Tooltip,
    Menu,
} from 'floating-vue';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(VueCookies, {
    expireTimes: '30d',
    path: '/',
    domain: '',
});

app.use(VueAwesomePaginate);

VSelect.props.components.default = () => ({
    OpenIndicator: IconSelectArrowVue,
});

app.component('v-select', VSelect);

app.use(VueSweetalert2);

app.use(VCalendar, {});

app.config.errorHandler = function (err, vm, info) {
    console.error('Vue Error:', err, info);
};

app.directive('tooltip', vTooltip);
app.directive('close-popper', vClosePopper);

app.component('VDropdown', Dropdown);
app.component('VTooltip', Tooltip);
app.component('VMenu', Menu);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(pinia).use(router).mount('#app');
