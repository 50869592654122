<template>
    <aside
        class="wrapper"
        :class="{ open: isSidebarOpen }">
        <nav ref="sideNav">
            <div class="logo">
                <img
                    src="@/assets/icons/svg/main-brand.svg"
                    alt="main-brand"
                    class="m-auto" />
            </div>
            <div class="menu">
                <div
                    v-for="(
                        itemValue, itemKey, itemIndex
                    ) in menuAccordionItems"
                    :key="itemIndex"
                    class="accordion"
                    :class="{ 'open-accordion': accordionsStatus[itemIndex] }">
                    <button
                        @click="toggleAccordion(itemIndex)"
                        class="heading">
                        <div class="icon">
                            <component
                                :is="itemValue.icon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: accordionsStatus[itemIndex],
                                }" />
                            <component
                                :is="itemValue.activeIcon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: !accordionsStatus[itemIndex],
                                }" />
                        </div>
                        <span>{{ itemKey }}</span>
                        <IconChevron
                            class="chevron-icon"
                            :color="
                                accordionsStatus[itemIndex]
                                    ? 'rgba(var(--black))'
                                    : 'rgba(var(--black) / 0.5)'
                            " />
                    </button>
                    <div class="body">
                        <ul>
                            <li
                                v-for="subitem in itemValue.subMenu"
                                :key="subitem.label">
                                <RouterLink
                                    :to="{ path: subitem.path }"
                                    @click="closeSidebar">
                                    <div class="circle"></div>
                                    <span>{{ subitem.label }}</span>
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-link
                    to="/users"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconUsers class="dashboard-icon normal-icon" />
                        <IconUsersActive class="dashboard-icon active-icon" />
                    </div>
                    <span>Users Management</span>
                </router-link>
                <router-link
                    to="/contractors"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconContractor class="dashboard-icon normal-icon" />
                        <IconContractorActive
                            class="dashboard-icon active-icon" />
                    </div>
                    <span>Contractor</span>
                </router-link>
            </div>
            <button
                class="logout"
                @click="openLogoutModal">
                <IconLogout />
                <span>Logout</span>
            </button>
        </nav>
    </aside>
    <ConfirmDialogue
        :is-open="isLogoutModalOpen"
        @cancel="closeLogoutModal"
        @confirm="myLogout"
        title="Log out"
        :icon="IconLogout"
        message="Are you sure want to log out?">
    </ConfirmDialogue>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
import IconDashboard from '../icons/sidebar/IconDashboard';
import IconDashboardActive from '../icons/sidebar/IconDashboardActive';
import IconFinishes from '../icons/sidebar/IconFinishes';
import IconFinishesActive from '../icons/sidebar/IconFinishesActive';
import IconSuites from '../icons/sidebar/IconSuites';
import IconSuitesActive from '../icons/sidebar/IconSuitesActive';
import IconChevron from '../icons/IconChevron.vue';
import IconUsers from '../icons/sidebar/IconUsers';
import IconUsersActive from '../icons/sidebar/IconUsersActive';
import IconContractor from '../icons/sidebar/IconContractor';
import IconContractorActive from '../icons/sidebar/IconContractorActive';
import IconLogout from '../icons/sidebar/IconLogout.vue';
import { useCookies } from 'vue3-cookies';
import ConfirmDialogue from './ConfirmDialogue.vue';
import { useRoute } from 'vue-router';
import { logout } from '@/services/axios/auth/logout.service';

const isLogoutModalOpen = ref(false);

function closeLogoutModal() {
    isLogoutModalOpen.value = false;
}

function openLogoutModal() {
    isLogoutModalOpen.value = true;
}

const props = defineProps({
    isSidebarOpen: Boolean,
});

const emits = defineEmits(['close-sidebar']);

const { cookies } = useCookies();

async function myLogout() {
    await logout();
    cookies.remove('token');
    localStorage.removeItem('user');
    location.reload();
}

const menuAccordionItems = {
    Dashboard: {
        icon: IconDashboard,
        activeIcon: IconDashboardActive,
        subMenu: [
            {
                label: 'Suites',
                path: '/dashboard/d-suites',
            },
            {
                label: 'Schedules',
                path: '/dashboard/schedules',
            },
            {
                label: 'Punch List',
                path: '/dashboard/punch-list',
            },
            // {
            //     label: 'Work Orders',
            //     path: '/',
            // },
            // {
            //     label: 'Documents',
            //     path: '/',
            // },
        ],
    },
    Finishes: {
        icon: IconFinishes,
        activeIcon: IconFinishesActive,
        subMenu: [
            {
                label: 'Spaces',
                path: '/finishes/spaces',
            },
            {
                label: 'Packages',
                path: '/finishes/packages',
            },
            {
                label: 'Add-ons',
                path: '/finishes/addons',
            },
        ],
    },
    'Suites Management': {
        icon: IconSuites,
        activeIcon: IconSuitesActive,
        subMenu: [
            {
                label: 'Suites',
                path: '/suites-management/suites',
            },
            {
                label: 'Models',
                path: '/suites-management/models',
            },
        ],
    },
};

const accordionsStatus = ref([false, false, false]); // close - close - close

const route = useRoute();

function openRelatedAccordion() {
    const path = route.path;
    if (path.includes('dashboard')) {
        accordionsStatus.value = [true, false, false];
    } else if (path.includes('finishes')) {
        accordionsStatus.value = [false, true, false];
    } else if (path.includes('suites-management')) {
        accordionsStatus.value = [false, false, true];
    } else {
        accordionsStatus.value = [false, false, false];
    }
}

onMounted(() => {
    openRelatedAccordion();
});

const sideNav = ref(null);

const closeSidebar = () => {
    emits('close-sidebar');
};

const closeSidebarAndAccordions = () => {
    accordionsStatus.value = [false, false, false];
    closeSidebar();
};

onClickOutside(sideNav, () => {
    if (props.isSidebarOpen) {
        emits('close-sidebar');
    }
});

function toggleAccordion(index) {
    accordionsStatus.value = accordionsStatus.value.map((status, i) =>
        i === index ? !status : false
    );
}
</script>

<style lang="scss" scoped>
aside.wrapper {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    z-index: 30;
    inset: 0;
    transition: all 0.4s;
    @media (width > 1024px) {
        visibility: visible;
        opacity: 1;
        z-index: 0;
        height: 100svh;
        background-color: rgb(var(--gray-100));
        width: 280px;
    }
    nav {
        overflow: auto;
        transition: all 0.4s;
        translate: -300px;
        width: min(75%, 300px);
        background-color: rgb(var(--white));
        height: 100%;
        border-radius: 0 24px 24px 0;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        @media (width > 1024px) {
            translate: 0;
            width: 280px;
        }
        .logo {
            padding: 56px 4px 56px;
        }
        .menu {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 8px;
            .accordion {
                .heading {
                    display: flex;
                    align-items: center;
                    transition: all 0.4s;
                    gap: 16px;
                    font-weight: 400;
                    padding: 10px 8px;
                    border-radius: 4px;
                    width: 100%;
                    color: rgba(var(--black) / 0.5);
                    .icon {
                        position: relative;
                        // background-color: red;
                        height: 24px;
                        width: 24px;
                        .dashboard-icon {
                            position: absolute;
                            width: 24px;
                            top: 0;
                            left: 0;
                            transition: all 0.4s;
                        }
                    }
                    .chevron-icon {
                        width: 18px;
                        margin-left: auto;
                        rotate: 180deg;
                        transition: all 0.4s;
                    }
                }
                .body {
                    padding: 0px 0px;
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 0.4s;
                    ul {
                        padding-left: 40px;
                        margin-top: 10px;

                        li {
                            a {
                                padding: 10px 16px;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                gap: 16px;
                                .circle {
                                    width: 10px;
                                    aspect-ratio: 1;
                                    border-radius: 50%;
                                    background-color: rgba(var(--black) / 0.2);
                                }
                                span {
                                    transition: all 0.4s;
                                    color: rgba(var(--black) / 0.5);
                                }
                            }
                            a.active,
                            a.router-link-active,
                            a.router-link-exact-active {
                                background-color: rgba(var(--blue) / 0.1);
                                .circle {
                                    background-color: rgb(var(--black));
                                }
                            }
                        }
                    }
                }
            }
            .open-accordion {
                .heading {
                    background-color: rgba(var(--blue) / 0.1);
                    span {
                        font-weight: 700;
                        color: rgb(var(--black));
                    }
                    .chevron-icon {
                        rotate: 0deg;
                    }
                }
                .body {
                    max-height: 300px;
                }
            }
            .link {
                display: flex;
                align-items: center;
                transition: all 0.4s;
                gap: 16px;
                font-weight: 400;
                padding: 10px 8px;
                border-radius: 4px;
                width: 100%;
                color: rgba(var(--black) / 0.5);
                .icon {
                    position: relative;
                    // background-color: red;
                    height: 24px;
                    width: 24px;
                    .dashboard-icon {
                        position: absolute;
                        width: 24px;
                        top: 0;
                        left: 0;
                        transition: all 0.4s;
                    }
                    .normal-icon {
                        opacity: 1;
                        visibility: visible;
                    }
                    .active-icon {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                .chevron-icon {
                    width: 18px;
                    margin-left: auto;
                    rotate: 180deg;
                    transition: all 0.4s;
                }
                &.router-link-active {
                    font-weight: 700;
                    color: rgb(var(--black));
                    background-color: rgba(var(--blue) / 0.1);
                    .normal-icon {
                        opacity: 0;
                        visibility: hidden;
                    }
                    .active-icon {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        .logout {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 10px 8px;
            margin-top: auto;
            margin-bottom: 52px;
            width: fit-content;
        }
    }
}
aside.wrapper.open {
    opacity: 1;
    visibility: visible;
    background-color: rgba(var(--black) / 0.2);
    backdrop-filter: blur(4px);
    nav {
        translate: 0;
    }
}
.hidden {
    opacity: 0;
    visibility: hidden;
}
</style>
