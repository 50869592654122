import axios from 'axios';
import { useStore } from '@/store/loading.js';

import { toast } from 'vue3-toastify';
import { useCookies } from 'vue3-cookies';

import router from '@/router';

const { cookies } = useCookies();

const errorToastConfig = {
    autoClose: 3000,
    position: toast.POSITION.BOTTOM_LEFT,
    type: toast.TYPE.ERROR,
    theme: toast.THEME.COLORED,
};

const successToastConfig = {
    autoClose: 3000,
    position: toast.POSITION.BOTTOM_LEFT,
    type: toast.TYPE.SUCCESS,
    theme: toast.THEME.COLORED,
};

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'X-CLIENT-ID': +process.env.VUE_APP_CLIENT_ID,
        'X-CLIENT-SECRET': process.env.VUE_APP_NOT_SECRET_CODE,
    },
});

export const httpClientWithoutLoading = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'X-CLIENT-ID': +process.env.VUE_APP_CLIENT_ID,
        'X-CLIENT-SECRET': process.env.VUE_APP_NOT_SECRET_CODE,
    },
});

httpClientWithoutLoading.interceptors.request.use(
    config => {
        const token = cookies.get('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        throw error;
    }
);

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use(
    config => {
        const store = useStore();
        numberOfAjaxCAllPending++;
        store.mutationer({ isLoading: true });
        const token = cookies.get('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        throw error;
    }
);

httpClient.interceptors.response.use(
    response => {
        const store = useStore();

        numberOfAjaxCAllPending--;

        // eslint-disable-next-line eqeqeq
        if (numberOfAjaxCAllPending == 0) {
            store.mutationer({ isLoading: false });
        }

        if (
            (response.config.method === 'post' ||
                response.config.method === 'put' ||
                response.config.method === 'delete') &&
            (response.status === 200 || response.status === 201)
        ) {
            setTimeout(() => {
                toast(response.data.message || 'seccess', successToastConfig);
            }, 0);
        }

        if (response.data) return response.data;
        else return response;
    },
    error => {
        const store = useStore();

        if (numberOfAjaxCAllPending > 0) numberOfAjaxCAllPending--;
        store.mutationer({ isLoading: false });

        toast(
            error?.response?.data?.message ||
                error?.response?.data?.result?.message ||
                error?.response?.data?.data?.message,
            errorToastConfig
        );

        if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
        ) {
            router.push({ name: 'login' });
        }

        throw error;
    }
);

export default httpClient;
