<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6501 4.79H16.3701C16.2701 4 15.9801 3.37 15.5201 2.9C14.9201 2.3 14.0501 2 12.9301 2H7.35011C5.10011 2 3.86011 3.24 3.86011 5.49C3.86011 6.61 4.16011 7.48 4.76011 8.08C5.36011 8.68 6.23011 8.98 7.35011 8.98H12.9301C14.9301 8.98 16.1301 7.99 16.3701 6.19H16.6501C18.7401 6.19 18.7401 6.47 18.7401 8.28V10.14C18.7401 11.95 18.7401 12.23 16.6501 12.23H12.9301C9.94011 12.23 9.51011 13.65 9.45011 15.03C8.55011 15.09 7.58011 15.51 7.58011 17.11V19.9C7.58011 21.63 8.72011 21.99 9.67011 21.99H10.6001C11.5501 21.99 12.6901 21.63 12.6901 19.9V17.11C12.6901 15.53 11.7401 15.09 10.8401 15.03C10.8901 14.02 11.1801 13.62 12.9201 13.62H16.6401C19.8901 13.62 20.1301 12.3 20.1301 10.13V8.28C20.1401 6.11 19.9001 4.79 16.6501 4.79Z"
            fill="#00094F" />
    </svg>
</template>
