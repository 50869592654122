import { createRouter, createWebHashHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

document.title = 'QDS-Admin Panel';

function lazyLoad(view) {
    return () => import(`../views/${view}.vue`);
}

const checkAuth = (to, from, next) => {
    const isAuthenticated = cookies.get('token');
    if (to.name === 'login' && isAuthenticated) {
        next('/');
    }
    if (!isAuthenticated) {
        next('/login');
    } else {
        next();
    }
};

const routes = [
    {
        path: '/',
        redirect: to => {
            return { name: 'd-suites' };
        },
    },
    {
        path: '/auth',

        children: [
            {
                path: '/login',
                name: 'login',
                component: lazyLoad('auth/LoginView'),
            },
            {
                path: '/forgot-pass',
                name: 'forgot-pass',
                component: lazyLoad('auth/ForgotPassView'),
            },
        ],
    },

    {
        path: '/test',
        name: 'test',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'test', requiresAuth: true },
        component: lazyLoad('test'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Dashboard', requiresAuth: true },
        redirect: to => {
            return { name: 'schedules' };
        },
        children: [
            {
                path: 'schedules',
                name: 'schedules',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Schedules',
                    requiresAuth: true,
                },
                component: lazyLoad('dashboard/SchedulesView'),
            },
            {
                path: 'punch-list',
                name: 'punch-list',
                beforeEnter: checkAuth,
                meta: { title: 'Punch List' },
                component: lazyLoad('dashboard/PunchListView'),
            },
            {
                path: 'd-suites',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Suites',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'd-suites',
                        beforeEnter: checkAuth,
                        meta: {
                            title: 'Suites',
                            requiresAuth: true,
                        },
                        component: lazyLoad('dashboard/suites/Suites'),
                    },
                    {
                        path: 'view',
                        name: 'view-dashboard-suite',
                        beforeEnter: checkAuth,
                        component: lazyLoad('dashboard/suites/ViewSuites'),
                        meta: {
                            title: 'View Suites',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/users',
        beforeEnter: checkAuth,
        meta: { layout: 'default', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'users',
                beforeEnter: checkAuth,
                meta: { title: 'Users Management' },
                component: lazyLoad('users/UsersView'),
            },
            {
                path: ':id',
                name: 'view-user',
                beforeEnter: checkAuth,
                meta: { title: 'View User' },
                component: lazyLoad('users/UserView'),
            },
            {
                path: 'add',
                name: 'add-user',
                beforeEnter: checkAuth,
                meta: { title: 'Add New User' },
                component: lazyLoad('users/CreateUserView'),
            },
            {
                path: 'edit-user',
                name: 'edit-user',
                beforeEnter: checkAuth,
                meta: { title: 'Edit User' },
                component: lazyLoad('users/EditUserView'),
            },
        ],
    },
    {
        path: '/profile',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Profile', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'profile',
                meta: { title: 'Profile' },
                component: lazyLoad('profile/ProfileView'),
            },
            {
                path: 'edit-pass',
                name: 'edit-pass',
                meta: { title: 'Reset Password' },
                component: lazyLoad('profile/EditPassView'),
            },
        ],
    },

    {
        path: '/finishes',
        name: 'finishes',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Finishes', requiresAuth: true },
        redirect: to => {
            return { name: 'spaces' };
        },
        children: [
            {
                path: 'packages',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Packages',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'packages',
                        beforeEnter: checkAuth,
                        meta: { title: 'Packages' },
                        component: lazyLoad('finishes/packages/Packages'),
                    },
                    {
                        path: 'add-package',
                        name: 'add-package',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'finishes/packages/createPackage/CreatePackage'
                        ),
                        meta: {
                            layout: 'default',
                            title: 'Add new package',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'view-package',
                        name: 'view-package',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/packages/ViewPackage'),
                        meta: {
                            layout: 'default',
                            title: 'View Package',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'edit-package',
                        name: 'edit-package',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/packages/EditPackage'),
                        meta: {
                            layout: 'default',
                            title: 'Edit',
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'spaces',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Spaces',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'spaces',
                        beforeEnter: checkAuth,
                        meta: { title: 'Spaces' },
                        component: lazyLoad('finishes/spaces/Spaces'),
                    },
                    {
                        path: 'add-space',
                        name: 'add-space',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/spaces/CreateSpace'),
                        meta: {
                            layout: 'default',
                            title: 'Add new space',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'view-space',
                        name: 'view-space',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/spaces/ViewSpace'),
                        meta: {
                            layout: 'default',
                            title: 'View Space',
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'addons',
                beforeEnter: checkAuth,
                children: [
                    {
                        path: '',
                        name: 'addons',
                        beforeEnter: checkAuth,
                        meta: {
                            layout: 'default',
                            title: 'Add-ons',
                            requiresAuth: true,
                        },
                        component: lazyLoad('finishes/addons/Addons'),
                    },
                    {
                        path: 'add',
                        name: 'add-addons',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/addons/CreateAddons'),
                        meta: {
                            layout: 'default',
                            title: 'Add New Add-ons',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'view',
                        name: 'view-addons',
                        beforeEnter: checkAuth,
                        component: lazyLoad('finishes/addons/ViewAddons'),
                        meta: {
                            layout: 'default',
                            title: 'View Add-ons',
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/suites-management',
        name: 'suitesManagement',
        beforeEnter: checkAuth,
        meta: {
            layout: 'default',
            title: 'Suites Management',
            requiresAuth: true,
        },
        redirect: to => {
            return { name: 'suites' };
        },
        children: [
            {
                path: 'suites',
                beforeEnter: checkAuth,
                children: [
                    {
                        path: '',
                        name: 'suites',
                        beforeEnter: checkAuth,
                        component: lazyLoad('suitesManagement/suites/Suites'),
                        meta: {
                            layout: 'default',
                            title: 'Suites',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'add-suite',
                        name: 'add-suite',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'suitesManagement/suites/CreateSuite'
                        ),
                        meta: {
                            layout: 'default',
                            title: 'Add new suite',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'view-suite',
                        name: 'view-suite',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'suitesManagement/suites/ViewSuite'
                        ),
                        meta: {
                            layout: 'default',
                            title: 'View Suite',
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'models',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Models',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'models',
                        beforeEnter: checkAuth,
                        component: lazyLoad('suitesManagement/models/Models'),
                        meta: {
                            layout: 'default',
                            title: 'Models',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'add-model',
                        name: 'add-model',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'suitesManagement/models/CreateModel'
                        ),
                        meta: {
                            layout: 'default',
                            title: 'Add new model',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'view-model',
                        name: 'view-model',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'suitesManagement/models/ViewModel'
                        ),
                        meta: {
                            layout: 'default',
                            title: 'View Model',
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/contractors',
        beforeEnter: checkAuth,
        children: [
            {
                path: '',
                name: 'contractors',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Contractors',
                    requiresAuth: true,
                },
                component: lazyLoad('contractors/Contractors'),
            },
            {
                path: 'add-contractor',
                name: 'add-contractor',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Add New Contractor',
                    requiresAuth: true,
                },
                component: lazyLoad('contractors/CreateContractor'),
            },
            {
                path: 'view-contractor',
                name: 'view-contractor',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'View Contractor',
                    requiresAuth: true,
                },
                component: lazyLoad('contractors/ViewContractor'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
