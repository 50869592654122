<template>
    <div class="app">
        <MainSidebar
            v-if="$route.meta.layout == 'default'"
            :is-sidebar-open="isSidebarOpen"
            @close-sidebar="closeSidebar" />
        <div
            :class="{ 'p-0': $route.meta.layout != 'default' }"
            class="main-section">
            <MainHeader
                v-if="$route.meta.layout == 'default'"
                @open-sidebar="openSidebar" />
            <main>
                <router-view />
            </main>
        </div>
    </div>
    <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue';
import MainHeader from '@/components/base/MainHeader';
import MainSidebar from '@/components/base/MainSidebar.vue';
import { computed, ref } from 'vue';
import { useStore } from '@/store/loading.js';

const store = useStore();

const isLoading = computed(() => {
    return store.isLoading;
});

// document.title = 'QDS-Admin-Panel'

const isSidebarOpen = ref(false);
function openSidebar() {
    isSidebarOpen.value = true;
}
function closeSidebar() {
    isSidebarOpen.value = false;
}
</script>

<style scoped lang="scss">
.app {
    display: flex;
    background-color: rgb(var(--gray-100));
    .main-section {
        // flex: 1;
        width: 100%;
        @media (width > 1024px) {
            padding-left: 280px;
        }
    }
}
</style>
